/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Layout, Alert } from '../components/common';

const encode = (data) => Object.keys(data)
  .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
  .join('&');

const Kontakt = ({ data }) => {
  const nameRef = useRef();
  const { title, html } = data.ghostPage;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    nameRef.current.focus();
  }, []);

  const submit = (e) => {
    e.preventDefault();
    setSuccess('');
    setError('');

    if (!name || !email || !message) {
      setError('Të gjitha fushat janë të patjetërsueshme!');
      return;
    }

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        name,
        email,
        message,
        'form-name': 'contact',
      }),
    })
      .then(() => {
        setName('');
        setEmail('');
        setMessage('');
        setSuccess('Porosia u dërgua me sukses. Ju faleminderit!');
      })
      .catch((err) => setError(err));
  };

  return (
    <Layout>
      <div className="container">
        <article
          className="page"
          style={{
            margin: '0 auto',
            maxWidth: 480,
          }}
        >
          <h1>{title}</h1>

          <section
            className="content-body load-external-scripts"
            dangerouslySetInnerHTML={{ __html: html }}
          />

          {success && <Alert type="success" text={success} />}
          {error && <Alert type="error" text={error} />}

          <form
            netlify
            name="contact"
            onSubmit={submit}
          >
            <input type="hidden" name="form-name" value="contact" />

            <label htmlFor="name">
              Emri:
              <input
                required
                id="name"
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                ref={nameRef}
              />
            </label>

            <label htmlFor="email">
              Email:
              <input
                required
                id="email"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>

            <label htmlFor="message">
              Porosia:
              <textarea
                required
                id="message"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </label>

            <input
              type="submit"
              value="DËRGO"
              className="primary"
              style={{ width: '100%' }}
            />
          </form>
        </article>
      </div>
    </Layout>
  );
};

Kontakt.propTypes = {
  data: PropTypes.shape({
    ghostPage: PropTypes.shape({
      codeinjection_styles: PropTypes.object,
      title: PropTypes.string.isRequired,
      html: PropTypes.string.isRequired,
      feature_image: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default Kontakt;

export const postQuery = graphql`
  query MyQuery {
    ghostPage(slug: {eq: "kontakt"}) {
      html
      title
    }
  }
`;
